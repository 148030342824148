import React, { useEffect, useState } from 'react';
import AppContainer from '../../containers/AppContainer';
import { AppBar, Toolbar, Theme, Typography, IconButton, Menu, MenuItem, Slide } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useStore } from '../../stores';
import useReactRouter from 'use-react-router';
import { observer } from 'mobx-react-lite';
import { TransitionProps } from '@material-ui/core/transitions/transition';

export interface IndexPageProps{
    title?: string
}

const useStyle = makeStyles((theme: Theme) => 
    createStyles({
        toolbar: theme.mixins.toolbar,
        root: {
            flexGrow: 1,
        },
        logo: {
            maxHeight:40, 
            marginRight:16,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },        
        account: {
            fontSize: 14,
        },
        menu: {
            maxHeight: 600,
        },
        menuitem: {
            fontSize: 14,        
        }
    }), {name: 'IndexPage'}
);

const AppsPage: React.FC = observer(() => {
    const store = useStore();
    const { history } = useReactRouter();
    const classes = useStyle();
    const [state,setState] = useState<{progress:boolean,
                                       authorized:boolean,
                                       snackOpened:boolean,
                                       anchorEl1:null|HTMLElement,
                                       anchorEl2:null|HTMLElement}>({
                                           progress:true,
                                           authorized:false,
                                           snackOpened:false,
                                           anchorEl1:null,
                                           anchorEl2:null});
    
    useEffect(() => { 
        if ( !state.authorized && store ) {
            store.appStore.fill().then(function(value) {
                if (value) setState({...state, authorized:true, progress:false});
                else history.push("/login");
            })
        }
    }, [store,state,history]);
    
    const logoutHandler = () => { 
        if (store)
            store.logout().then(function(value) {
                if (value) {
                    store.setAuthorized(false);
                    history.push("/login");
                }
            })
    };

    const handleMenuChdom = (event: React.MouseEvent<HTMLElement>) => {        
        if (store && store.domains && store.domains.length>0)
            setState({...state, anchorEl1:event.currentTarget});
    };

    const handleMenuExit = (event: React.MouseEvent<HTMLElement>) => {        
        setState({...state, anchorEl2:event.currentTarget});
    };

    const handleCloseChdom = (domain:string) => (e: any) => {
        if (domain!=="") {
            setState({...state, progress:true, anchorEl1:null});
            if (store)
            {
                store.changeDomain(domain).then(function(valueChanged) {
                    if (valueChanged) {
                        store.appStore.fill().then(function(valueFilled) {
                            if (valueFilled) setState({...state, authorized:true, progress:false, anchorEl1:null, snackOpened:false});
                            else { history.push("/login"); return; }
                        })
                    }                
                })
            }
        };
        setState({...state, progress:false, anchorEl1:null, snackOpened:false});
    };

    const handleCloseExit = (ExitType:string) => (e: any) => {
        if (ExitType !== "")
        {
            if (ExitType === "closeAllSessions" && store)
            {
                store.clearOtherSessions();
            }
            else
            {
                logoutHandler();
            }
        }
        setState({...state, anchorEl2:null});
    };

    const draw = store && !store.isLoading && state.authorized
        ?   
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <img src="/rest/v1/public/resources/logo" className={classes.logo} alt=""/>
                        <Typography variant="h6" className={classes.title}>{""}</Typography>
                        {/* <Typography variant="h6" className={classes.title}>{state.progress ? "" : (store ? store.getTitle() : "")}</Typography> */}

                        <Typography variant="h6" className={classes.account}>{store ? store.getAccountName() : ""}</Typography>

                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenuChdom}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            className={classes.menu}
                            anchorEl={state.anchorEl1}
                            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                            keepMounted
                            transformOrigin={{vertical: 'top',horizontal: 'right'}}
                            open={state.anchorEl1 != null}
                            onClose={handleCloseChdom("")}
                        >
                            {store.domains.map((Domain:string) => <MenuItem className={classes.menuitem} key={Domain} onClick={handleCloseChdom(Domain)}>{"@ " + Domain}</MenuItem>)}
                        </Menu>

                        <IconButton
                            aria-label="exit type"
                            aria-controls="exit-menu"
                            aria-haspopup="true"
                            onClick={handleMenuExit}
                            color="inherit"
                        >
                            <ExitToAppIcon />
                        </IconButton>

                        <Menu
                            id="exit-menu"
                            anchorEl={state.anchorEl2}
                            keepMounted
                            open={Boolean(state.anchorEl2)}
                            onClose={handleCloseExit("")}
                        >
                            <MenuItem onClick={handleCloseExit("exit")}>Выйти</MenuItem>
                            <MenuItem onClick={handleCloseExit("closeAllSessions")}>Закрыть остальные сессии</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>         
                <div style={{height:40}}></div>
                {!state.progress && <AppContainer />}
            </div>
        :   
            <div />
            
    return (
        <React.Fragment>
            { draw }            
        </React.Fragment>
    );
});

export default AppsPage;